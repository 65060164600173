import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'

export default function useLogList() {
  // Use toast
  const toast = useToast()

  const refLogListTable = ref(null)

  // Table Handlers
  const tableLogColumns = [
    { label: 'Product', key: 'saleID', sortable: true },
    { label: 'Order ID', key: 'salesdockID', sortable: true },
    { label: 'Naam', key: 'ibanName', sortable: true },
    { label: 'Status', key: 'status', sortable: false },
    { label: 'Import datum', key: 'fetchTimestamp', sortable: true },
    { label: 'Acties', key: 'actions' },
  ]

  const perPage = ref(25)
  const totalActivities = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50, 75, 100]
  const showOverlay = ref(true)
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refLogListTable.value ? refLogListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalActivities.value,
    }
  })

  const refetchData = () => {
    refLogListTable.value.refresh()
  }

  watch([currentPage, perPage, statusFilter], () => {
    refetchData()
  })

  const userOptions = []

  const fetchLogs = (ctx, callback) => {
    store
      .dispatch('app-gaslicht/fetchActivities', {
        perPage: perPage.value,
        page: currentPage.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        totalActivities.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ophalen Aciviteiten',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${errorAndSuccesDefaultMessage.fetchDataError}`,
          },
        })
      })
  }

  const resolveStatusName = status => {
    if (status === 'NEW') return 'Nieuw'
    if (status === 'PROCESSING') return 'Inbehandeling'
    if (status === 'FINISHED') return 'Verwerkt'
    if (status === 'FAILED') return 'Actie vereist'
    if (status === 'MANUAL_FIXED') return 'Handmatig verwerkt'
    if (status === 'DUPLICATE') return 'Duplicaat'
    if (status === 'CANCEL') return 'Geannulleerd'
    return 'Nieuw'
  }

  const resolveStatusVariant = status => {
    if (status === 'NEW') return 'secondary'
    if (status === 'PROCESSING') return 'primary'
    if (status === 'FINISHED') return 'success'
    if (status === 'FAILED') return 'danger'
    if (status === 'MANUAL_FIXED') return 'success'
    if (status === 'DUPLICATE') return 'danger'
    if (status === 'CANCEL') return 'danger'
    return 'secondary'
  }

  const statusOptions = [
    { label: 'Nieuw', value: 'NEW' },
    { label: 'Inbehandeling', value: 'PROCESSING' },
    { label: 'Verwerkt', value: 'FINISHED' },
    { label: 'Actie vereist', value: 'FAILED' },
    { label: 'Handmatig verwerkt', value: 'MANUAL_FIXED' },
    { label: 'Duplicaat', value: 'DUPLICATE' },
    { label: 'Geannulleerd', value: 'CANCEL' },
  ]

  return {
    fetchLogs,
    tableLogColumns,
    perPage,
    currentPage,
    totalActivities,
    dataMeta,
    perPageOptions,
    refLogListTable,
    refetchData,
    statusOptions,
    userOptions,
    showOverlay,
    resolveStatusName,
    statusFilter,
    resolveStatusVariant,
  }
}
