<template>

  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <b-modal
      id="modal-gaslicht-status"
      ref="modalGaslichtStatus"
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-disabled
      cancel-title="Close"
      centered
      :no-close-on-backdrop="true"
      title="Status aanpassen"
      :hide-footer="true"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refModalGaslichtStatusObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(changeStatus)"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="disbarmentNote"
                rules="required"
              >
                <b-form-group>
                  <label>Selecteer een status</label>
                  <v-select
                    v-model="changeStatusData.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :value="statusFilter"
                    :options="statusOptions"
                    class="w-100"
                    :reduce="val => val.value"
                    @input="(val) => $emit('update:statusFilter', val)"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                name="eanNumberElectricity"
                rules=""
              >
                <b-form-group
                  label="Elektriciteit EAN"
                  label-for="eanNumberElectricity"
                >
                  <b-form-input
                    id="electricity_ean"
                    v-model="changeStatusData.eanNumberElectricity"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                name="eanNumberGas"
                rules=""
              >
                <b-form-group
                  label="Gas EAN"
                  label-for="eanNumberGas"
                >
                  <b-form-input
                    id="eanNumberGas"
                    v-model="changeStatusData.eanNumberGas"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              cols="12"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <b-spinner
                  v-if="showChangeStatusSpinner"
                  small
                  class="mr-1"
                />
                Aanpassen
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- Filters -->
    <log-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Toon</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>items</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          />
        </b-row>

      </div>

      <b-table
        ref="refLogListTable"
        class="position-relative"
        :items="fetchLogs"
        responsive
        :fields="tableLogColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >

        <!-- Column: Id -->
        <template #cell(saleID)="data">
          <span class="small text-nowrap"><strong>{{ data.item.productName }}</strong></span><br>
          <span class="small">{{ data.value }}</span>
        </template>
        <template #cell(salesdockID)="data">
          <span class="small">{{ data.value }}</span>
        </template>
        <template #cell(ibanName)="data">
          <span class="small text-nowrap"><strong>{{ data.value }}</strong></span><br>
          <span class="small text-nowrap">{{ data.item.connectionStreet }} {{ data.item.connectionHousenumber }} {{ data.item.connectionHousenumberAddition && data.item.connectionHousenumberAddition !== '' ? data.item.connectionHousenumberAddition : '' }}</span><br>
          <span class="small text-nowrap">{{ data.item.connectionPostalcode }}, {{ data.item.connectionCity }}</span>
        </template>
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatusName(data.value) }}
          </b-badge>
        </template>
        <template #cell(fetchTimestamp)="data">
          <span class="small">{{ data.value }}</span>
        </template>
        <template #cell(actions)="data">
          <div
            class="text-nowrap"
          >
            <feather-icon
              v-if="data.item.errorMessage && data.item.status === 'FAILED'"
              :id="`log-row-${data.item.saleID}-data-icon`"
              class="cursor-pointer mr-1 feather-hover"
              icon="InfoIcon"
              size="16"
              style="color:#fdb813"
            />
            <b-tooltip
              v-if="data.item.errorMessage"
              :target="`log-row-${data.item.saleID}-data-icon`"
              placement="left"
            >
              <p class="mb-0">
                {{ data.item.errorMessage }}
              </p>
            </b-tooltip>

            <feather-icon
              v-if="data.item.status !== 'FINISHED' && data.item.status !== 'NEW'"
              :id="`status-row-${data.item.saleID}-data-icon`"
              class="cursor-pointer mr-1 feather-hover"
              icon="EditIcon"
              size="16"
              style="color:#fdb813"
              @click="openChangeStatusModal(data.item.saleID)"
            />
            <b-tooltip
              :target="`status-row-${data.item.saleID}-data-icon`"
              placement="left"
            >
              <p class="mb-0">
                Status aanpassen
              </p>
            </b-tooltip>

            <download-excel
              class="btn ml-0 mr-0 p-0"
              :fields="excel_fields"
              :data="[data.item]"
              worksheet="Sheet 1"
              :name="`${data.item.saleID}.xls`"
            >

              <feather-icon
                :id="`download-row-${data.item.saleID}-data-icon`"
                class="cursor-pointer feather-hover"
                icon="DownloadIcon"
                size="16"
                style="color:#fdb813"
              />
              <b-tooltip
                :target="`download-row-${data.item.saleID}-data-icon`"
                placement="left"
              >
                <p class="mb-0">
                  Export downloaden
                </p>
              </b-tooltip>

            </download-excel>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalActivities"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  BOverlay,
  BTooltip,
  BBadge,
  VBToggle,
  BButton,
  BFormInvalidFeedback,
  BSpinner,
  BFormGroup, BForm, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { formatDateTime } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required,
} from '@core/utils/validations/validations'
import LogFilters from './gaslichtFilters.vue'
import useGaslichtList from './useGaslichtList'
import gaslichtStoreModule from './gaslichtStoreModule'

export default {
  components: {
    LogFilters,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BOverlay,
    vSelect,
    BTooltip,
    BBadge,
    BButton,
    BFormInvalidFeedback,
    BSpinner,
    BFormGroup,
    BForm,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      excel_fields: {
        saleID: 'saleID',
        connectionPostalcode: 'connectionPostalcode',
        connectionHousenumber: 'connectionHousenumber',
        connectionHousenumberAddition: 'connectionHousenumberAddition',
      },
      required,
      changeStatusData: {
        status: null,
        saleID: null,
        eanNumberElectricity: null,
        eanNumberGas: null,
      },
      showChangeStatusSpinner: false,
    }
  },
  methods: {
    openChangeStatusModal(saleID) {
      this.changeStatusData.saleID = saleID
      this.$refs.modalGaslichtStatus.show()
    },
    changeStatus() {
      this.showChangeStatusSpinner = true
      store.dispatch('app-gaslicht/patchActivity', this.changeStatusData)
        .then(() => {
          this.refetchData()
          this.$refs.modalGaslichtStatus.hide()
          this.showChangeStatusSpinner = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Status wijzigen',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: `${errorAndSuccesDefaultMessage.updateDataSucces}`,
            },
          })
        })
        .catch(() => {
          this.showChangeStatusSpinner = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Status wijzigen',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `${errorAndSuccesDefaultMessage.updateDataError}`,
            },
          })
        })
    },
  },
  setup() {
    const GASLICHT_APP_STORE_MODULE_NAME = 'app-gaslicht'

    // Register module
    if (!store.hasModule(GASLICHT_APP_STORE_MODULE_NAME)) store.registerModule(GASLICHT_APP_STORE_MODULE_NAME, gaslichtStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(GASLICHT_APP_STORE_MODULE_NAME)) store.unregisterModule(GASLICHT_APP_STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const {
      fetchLogs,
      tableLogColumns,
      perPage,
      currentPage,
      typeFilter,
      userFilter,
      totalActivities,
      dataMeta,
      perPageOptions,
      refLogListTable,
      refetchData,
      showOverlay,
      resolveStatusName,
      statusOptions,
      statusFilter,
      resolveStatusVariant,
    } = useGaslichtList()

    return {
      fetchLogs,
      tableLogColumns,
      perPage,
      currentPage,
      typeFilter,
      userFilter,
      totalActivities,
      dataMeta,
      perPageOptions,
      refLogListTable,
      refetchData,
      showOverlay,
      formatDateTime,
      resolveStatusName,
      statusOptions,
      statusFilter,
      resolveStatusVariant,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
